<template>
  <div class="setting">
    <div class="tab">
      <img class="imgs" :src="`${imgUrl}/img/back.png`" @click="goback" mode="widthFix">
      <div class="tab-title">Android版本安裝說明</div>
    </div>

    <div style="padding:20px;margin-top:55px">
          <div class="title">請仔細閱讀安裝說明再操作</div>
    <div class="various">
      <div class="item" v-for="(item,index) in variousList" :key="index" @click="gotochild(index,item)">{{item}}手機安裝App和殺毒設置</div>
    </div>

     <div class="title" style="margin-top:40px;font-size:20px">手機報病毒禁止安裝處理</div>
      <div class="title2 title">第一步，打开系统设置，找到「安全与隐私」裝；</div>
      <img :src="`${imgUrl}/img/Android/HOME/1.png`" mode="widthFix">
      <div class="title2 title">第二步，点击「更多安全设置」</div>
      <img :src="`${imgUrl}/img/Android/HOME/2.png`" mode="widthFix">
      <div class="title2 title">第三步，找到「禁止安装恶意应用」，并关闭</div>
      <img :src="`${imgUrl}/img/Android/HOME/3.png`" mode="widthFix">
    </div>



  </div>
</template>

<script>
export default {
  data(){
    return{
     variousList:["華為","VIVO","OPPO","小米","魅族","其他"] 
    }
  },
  methods:{
    gotochild(index,item){
      this.$router.push({path:'/various',query:{id:index+1,name:item}})
    },
     goback() {
      this.$router.go(-1)
    }

  },
  mounted(){
  }
}
</script>

<style scoped lang="less">
  .setting{
    .tab{
      background-color: #fff;
      width: 100%;
        position: fixed;
    top: 0;
    left: 0;
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      .imgs{
        padding: 0;
        box-sizing: initial;
        width: 20px;
        height: 20px;
        z-index: 100;
        
      }
      .tab-title{
        flex: 1;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: #000;
      }
    }
    .title{
      font-size: 14px;
      color: #333;
      font-weight: 600;
      text-align: center;
    }
    .title2{
      text-align: left;
      padding: 0 15px;
      margin: 20px 0;
    }
    img{
      padding: 30px;
      width: 100%;
      height: 100%;
    }
    .various{
      padding: 0 15px;
      width: calc(100% - 30px);
      margin: 0 auto;
      margin-top: 20px;
        .item{
          height: 35px;
          margin-bottom: 20px;
          font-size: 16px;
          background-color: #4989f2;
          color: #fff;
          line-height: 35px;
          text-align: center;
        }
    }
  }
</style>